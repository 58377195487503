import { generateHexString } from '../../helpers';

export const mapMilitaryStatusChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapMilitaryStatusOutputsToChartData = (siteId, results?) => {
  const ProtectedUsVeteranColor = `#${generateHexString(6)}`;
  const ActiveMilitaryDutyColor = `#${generateHexString(6)}`;
  const OtherColor = `#${generateHexString(6)}`;
  const NotApplicableColor = `#${generateHexString(6)}`;
  const PreferNotToDiscloseColor = `#${generateHexString(6)}`;

  const isSiteChosen = siteId !== 'none';

  const chartData = [
    {
      name: 'Protected U.S. Veteran',
      patientsCount:
        isSiteChosen && !!results?.totalProtectedUsVeteran
          ? results?.totalProtectedUsVeteran
          : 0,
      color: ProtectedUsVeteranColor
    },
    {
      name: 'Active/Reserve Military Duty',
      patientsCount:
        isSiteChosen && !!results?.totalActiveReserveMilitaryDuty
          ? results?.totalActiveReserveMilitaryDuty
          : 0,
      color: ActiveMilitaryDutyColor
    },
    {
      name: 'Other',
      patientsCount:
        isSiteChosen && !!results?.totalOther ? results?.totalOther : 0,
      color: OtherColor
    },
    {
      name: 'Not Applicable',
      patientsCount:
        isSiteChosen && !!results?.totalNotApplicable
          ? results?.totalNotApplicable
          : 0,
      color: NotApplicableColor
    },
    {
      name: 'Prefer not to disclose',
      patientsCount:
        isSiteChosen && !!results?.totalPreferNotToDisclose
          ? results?.totalPreferNotToDisclose
          : 0,
      color: PreferNotToDiscloseColor
    }
  ];

  return chartData;
};

export const mapMilitaryStatusOutputsToExportedTable = (data, siteName) => [
  {
    id: 1,
    militaryStatus: 'Protected U.S. Veteran',
    count: data?.totalProtectedUsVeteran,
    percentage: `${
      Math.round(
        (data?.totalProtectedUsVeteran / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 2,
    militaryStatus: 'Active/Reserve Military Duty',
    count: data?.totalActiveReserveMilitaryDuty,
    percentage: `${
      Math.round(
        (data?.totalActiveReserveMilitaryDuty / (data?.totalPatients || 1)) *
          100 *
          10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 3,
    militaryStatus: 'Other',
    count: data?.totalOther,
    percentage: `${
      Math.round((data?.totalOther / (data?.totalPatients || 1)) * 100 * 10) /
      10
    }%`,
    site: siteName
  },
  {
    id: 4,
    militaryStatus: 'Not Applicable',
    count: data?.totalNotApplicable,
    percentage: `${
      Math.round(
        (data?.totalNotApplicable / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 5,
    militaryStatus: 'Prefer not to disclose',
    count: data?.totalPreferNotToDisclose,
    percentage: `${
      Math.round(
        (data?.totalPreferNotToDisclose / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  }
];

const getMilitaryStatus: { [key: string]: string } = {
  'Protected U.S. Veteran': 'PROTECTED_US_VETERAN',
  'Active/Reserve Military Duty': 'ACTIVE_RESERVE_MILITARY_DUTY',
  Other: 'OTHER',
  'Not Applicable': 'NOT_APPLICABLE',
  'Prefer not to disclose': 'PREFER_NOT_TO_DISCLOSE'
};

const getMilitaryStatusLabel: { [key: string]: string } = {
  PROTECTED_US_VETERAN: 'Protected U.S. Veteran',
  ACTIVE_RESERVE_MILITARY_DUTY: 'Active/Reserve Military Duty',
  OTHER: 'Other',
  NOT_APPLICABLE: 'Not Applicable',
  PREFER_NOT_TO_DISCLOSE: 'Prefer not to disclose'
};

export const mapMilitaryStatusOutputsToTable = patients =>
  patients.map(patient => ({
    id: patient?.patientId,
    name: patient?.patientName,
    age: patient?.age,
    site: patient?.siteName,
    assignedStaff: patient?.primaryStaffName,
    militaryStatus: getMilitaryStatusLabel[patient?.militaryStatus]
  }));

export const mapMilitaryStatusTableInputs = (
  createdFrom,
  createdTo,
  militaryStatus,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    militaryStatus: getMilitaryStatus[militaryStatus]
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      name: 'PATIENT_NAME',
      age: 'AGE',
      site: 'SITE_NAME',
      assignedStaff: 'PRIMARY_STAFF_NAME',
      militaryStatus: 'MILITARY_STATUS'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};
