import { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { useQuery, useLazyQuery } from '@apollo/client';

import Routes from './routes';
import { roleModel } from './models';
import { ROLES } from './utils/constants';
import { GET_ALL_SITES, GET_LOCAL_STATES } from './graphQL/queries';
import { localStates, useLocalStates } from './graphQL/states/localStates';

function App() {
  const {
    data: {
      localStates: { userRole, isLoggedIn }
    }
  } = useQuery(GET_LOCAL_STATES);

  const { editSites, editUserRole, editIsLoggedIn } =
    useLocalStates(localStates);

  const [getAllSites] = useLazyQuery(GET_ALL_SITES, {
    onCompleted: data => updateSites(data)
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => editIsLoggedIn(true))
      .catch(() => {
        editIsLoggedIn(false);
        localStorage.clear();
      });
  }, []);

  useEffect(() => {
    if (isLoggedIn) getAllSites();
  }, []);

  const updateSites = data => {
    const sites = data?.getAllSites;

    if (sites) {
      let siteIdCheck = false;
      let role = localStorage.getItem('role') as roleModel;
      const defaultSiteId = localStorage.getItem('defaultSiteId');

      if (defaultSiteId) {
        if (defaultSiteId === 'all_sites' && userRole === ROLES.GLOBAL_ADMIN) {
          role = ROLES.GLOBAL_ADMIN;
          siteIdCheck = true;
        } else {
          sites.map(({ id, children, role: parentRole }) => {
            if (id === defaultSiteId) {
              role = parentRole;
              siteIdCheck = true;
            }
            if (children) {
              children.map(({ id: childId, role: childRole }) => {
                if (childId === defaultSiteId) {
                  role = childRole;
                  siteIdCheck = true;
                }
              });
            }
          });
        }
      } else {
        role = sites[0]?.role;
        let siteId = sites[0]?.id;
        if (role === ROLES.GLOBAL_ADMIN) siteId = 'all_sites';
        localStorage.setItem('defaultSiteId', siteId);
      }

      if (defaultSiteId && !siteIdCheck) {
        localStorage.removeItem('role');
        localStorage.removeItem('defaultSiteId');
        window.location.reload();
      }

      localStorage.setItem('sites', JSON.stringify(sites));
      editSites(sites);

      localStorage.setItem('role', role);
      const localRole = localStorage.getItem('role') as roleModel;
      const roleCheck = Object.values(ROLES).includes(localRole);

      editUserRole((roleCheck && localRole) || ROLES.GUEST);
    }
  };

  return <Routes isLoggedIn={isLoggedIn} />;
}

export default App;
