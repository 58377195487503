import { generateHexString } from '../../helpers';

const getEthnicity: { [key: string]: string } = {
  Yes: 'YES',
  No: 'NO',
  'Not Completed': 'NOT_COMPLETED',
  Unknown: 'UNKNOWN',
  'Prefer not to disclose': 'PREFER_NOT_TO_DISCLOSE'
};

const getEthnicityLabel: { [key: string]: string } = {
  YES: 'Yes',
  NO: 'No',
  NOT_COMPLETED: 'Not Completed',
  UNKNOWN: 'Unknown',
  PREFER_NOT_TO_DISCLOSE: 'Prefer not to disclose'
};

export const mapEthnicityChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapEthnicityTableInputs = (
  createdFrom,
  createdTo,
  ethnicity,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    ethnicity: getEthnicity[ethnicity]
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME',
      age: 'AGE',
      siteName: 'SITE_NAME',
      primaryStaffName: 'PRIMARY_STAFF_NAME',
      ethnicity: 'ETHNICITY'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapEthnicityOutputsToChartData = (siteId, results?) => {
  const yesColor = `#${generateHexString(6)}`;
  const noColor = `#${generateHexString(6)}`;
  const notCompletedColor = `#${generateHexString(6)}`;
  const unknownColor = `#${generateHexString(6)}`;
  const notDiscloseColor = `#${generateHexString(6)}`;

  let chartData = [
    {
      name: 'Yes',
      patientsCount: 0,
      color: yesColor
    },
    { name: 'No', patientsCount: 0, color: noColor },
    { name: 'Not Completed', patientsCount: 0, color: notCompletedColor },
    { name: 'Unknown', patientsCount: 0, color: unknownColor },
    {
      name: 'Prefer not to disclose',
      patientsCount: 0,
      color: notDiscloseColor
    }
  ];

  if (siteId !== 'none' && results) {
    chartData = [
      {
        name: 'Yes',
        patientsCount: results?.totalYes || 0,
        color: yesColor
      },
      { name: 'No', patientsCount: results?.totalNo || 0, color: noColor },
      {
        name: 'Not Completed',
        patientsCount: results?.totalNotCompleted || 0,
        color: notCompletedColor
      },
      {
        name: 'Unknown',
        patientsCount: results?.totalUnknown || 0,
        color: unknownColor
      },
      {
        name: 'Prefer not to disclose',
        patientsCount: results?.totalPreferNotToDisclose || 0,
        color: notDiscloseColor
      }
    ];
  }

  return chartData;
};

export const mapEthnicityOutputsToTable = patients =>
  patients.map(patient => ({
    id: patient?.patientId,
    patientName: patient?.patientName,
    age: patient?.age,
    siteName: patient?.siteName,
    primaryStaffName: patient?.primaryStaffName,
    ethnicity: getEthnicityLabel[patient?.ethnicity]
  }));
