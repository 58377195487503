import * as yup from 'yup';

export const patientProfileValidationSchema = yup.object().shape({
  id: yup.string().required(),
  firstName: yup
    .string()
    .required('First name is required')
    .test('names', 'Please enter a valid first name', value =>
      /^([a-zA-Z.'-]+\s)*[a-zA-Z.'-]+$/.test(value as string)
    ),
  middleName: yup
    .string()
    .test('names', 'Please enter a valid middle name', value =>
      /^([a-zA-Z.'-]+\s)*[a-zA-Z.'-]+$|^$/.test(value as string)
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .test('names', 'Please enter a valid last name', value =>
      /^([a-zA-Z.'-]+\s)*[a-zA-Z.'-]+$/.test(value as string)
    ),
  date: yup
    .date()
    .min(new Date('01/01/1900'), 'Date should be later than 01/01/1900')
    .max(
      new Date(),
      `Date should be before ${new Date().toLocaleString().replace(/,.*/, '')}`
    )
    .nullable(true)
    .required('Date of birth is required'),
  gender: yup.string().required('Gender is required'),
  status: yup.string(),
  email: yup.string().email('Please enter a valid email format'),
  primaryPhone: yup
    .string()
    .required('Primary phone number is required')
    .test(
      'phone-number',
      'Please enter a valid number in the format of 555-555-5555.',
      value => /^[0-9]{3}[-]?[0-9]{3}[-][0-9]{4}$/im.test(value as string)
    ),
  secondaryPhone: yup
    .string()
    .test(
      'phone-number',
      'Please enter a valid number in the format of 555-555-5555.',
      value => /^[0-9]{3}[-]?[0-9]{3}[-][0-9]{4}$|^$/im.test(value as string)
    ),
  emergFullName: yup
    .string()
    .test('names', 'Please enter a valid name', value =>
      /^([a-zA-Z.'-]+\s)*[a-zA-Z.'-]+$|^$/.test(value as string)
    ),
  emergRelation: yup.string(),
  emergPhone: yup
    .string()
    .test(
      'phone-number',
      'Please enter a valid number in the format of 555-555-5555.',
      value => /^[0-9]{3}[-]?[0-9]{3}[-][0-9]{4}$|^$/im.test(value as string)
    ),
  race: yup.string().required('Race is required'),
  ethnicity: yup.string().required('Origin is required'),
  militaryStatus: yup.string().required('Military status is required')
});
