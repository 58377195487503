import { gql } from '@apollo/client';

export const GET_PATIENT_REPORT = gql`
  query getAssessmentReport($data: GetAssessmentInput!) {
    getAssessmentReport(data: $data) {
      id
      fallInPast3Months
      numberOfFalls
      fallDescription
      fallInjuries
      amountOfPainExperienced
      visitedHospital
      visitedPlaceType
      visitDate
      allergies
      allergyNote
      conditions
      conditionNote
      dizzy
      dizzinessDetails
      confusion
      daysOfAlcoholUsagePerWeek
      numberOfDrinksPerDay
      bpLyingNum
      bpLyingDenom
      lyingPulse
      lyingPressureCapturedAt
      bpSittingNum
      bpSittingDenom
      sittingPulse
      sittingPressureCapturedAt
      bpStandingNum
      bpStandingDenom
      standingPulse
      standingPressureCapturedAt
      attachment
      disclaimer
      drugs {
        name
        route
        dosage
        dosageMeasurement
        quantity
        direction
        quantityMeasurement
        frequency
        notes
        providerId
        pharmacyId
        providerName
        pharmacyName
        asNeeded
      }
      patient {
        id
        firstName
        lastName
        date
        gender
        primaryPhone
        primaryAddressId
        optionalPatientSiteId
        addresses {
          id
          address1
          address2
          city
          state
          zip
        }
        pharmacies {
          npi
          name
          phone
          fax
          address
          state
          city
          zip
          active
        }
        providers {
          npi
          type
          firstName
          lastName
          phone
          fax
          address
          state
          city
          zip
          active
        }
      }
    }
  }
`;
