import LogoutTimer from '../../sharedComponents/LogoutTimer';
import HomeRepresentation from './HomeRepresentation';

const Home = () => (
  <>
    <HomeRepresentation />
    <LogoutTimer />
  </>
);

export default Home;
