import React from 'react';

import classNames from 'classnames';
import { Select, Typography, FormControl } from '@material-ui/core';

import { styles } from './styles';
import { WarningIcon, ArrowDownDropdownIcon } from '../../assets/icons';

interface IDropdown {
  children?: any;
  label?: String;
  errorMsg?: string;
  handleItems?: any;
  renderValue?: any;
  required?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  selectedItem?: String;
  customMenuClass?: any;
  defaultValue?: String;
  styleNoBorder?: boolean;
  customSelectClass?: any;
  fullWidthItems?: boolean;
  styleLongInput?: boolean;
  styleShortInput?: boolean;
  styleGreyBorder?: boolean;
  styleDistantLabel?: boolean;
  labelComponent?: JSX.Element;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Dropdown = ({
  Icon,
  label,
  required,
  multiple,
  children,
  errorMsg,
  fullWidth,
  handleItems,
  renderValue,
  placeholder,
  selectedItem,
  defaultValue,
  labelComponent,
  styleLongInput,
  fullWidthItems,
  styleGreyBorder,
  styleShortInput,
  customMenuClass,
  styleDistantLabel,
  customSelectClass,
  styleNoBorder = false
}: IDropdown) => {
  const classes = styles();

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        variant="outlined"
        classes={{
          root: classNames(
            { [classes.fullWidth]: fullWidth },
            { [classes.formControlError]: errorMsg }
          )
        }}
      >
        {labelComponent ||
          (label && (
            <Typography
              className={classNames(classes.dropdownLabel, {
                [classes.distantLabel]: styleDistantLabel
              })}
            >
              {required ? `${label}*` : label}
            </Typography>
          ))}
        <Select
          required={required}
          disabled={!multiple}
          value={selectedItem}
          fullWidth={fullWidth}
          onChange={handleItems}
          defaultValue={defaultValue}
          displayEmpty={Boolean(placeholder)}
          renderValue={
            placeholder && !selectedItem
              ? () => <div className={classes.placeholder}>{placeholder}</div>
              : renderValue
          }
          IconComponent={
            multiple
              ? rest =>
                  Icon ? (
                    <Icon {...rest} />
                  ) : (
                    <ArrowDownDropdownIcon {...rest} />
                  )
              : () => <></>
          }
          MenuProps={{
            className: classNames(
              classes.dropdownMenu,
              {
                [customMenuClass]: customMenuClass
              },
              { [classes.fullWidthContainer]: fullWidthItems }
            )
          }}
          className={classNames(
            classes.dropdownSelect,
            customSelectClass,
            { [classes.border]: !styleNoBorder },
            { [classes.longInput]: styleLongInput },
            { [classes.greyBorder]: styleGreyBorder },
            { [classes.shortInput]: styleShortInput }
          )}
        >
          {children}
        </Select>
      </FormControl>
      {errorMsg && (
        <label className={classes.errorText}>
          <WarningIcon className={classes.warningLogo} />
          {errorMsg}
        </label>
      )}
    </div>
  );
};

export default Dropdown;
