import { styles } from './styles';
import Heading from '../../../../sharedComponents/heading';
import Dropdown from '../../../../sharedComponents/dropdown';
import CustomInput from '../../../../sharedComponents/customInput';
import DropdownItem from '../../../../sharedComponents/dropdownItem';
import {
  CONTACT_INFO,
  EMERGENCY_CONTACT_OPTIONS
} from '../../../../utils/constants';

interface IContactInfo {
  watch: any;
  errors: any;
  handleRegister: any;
  handlePhoneNumber: (e: any, fieldName: any) => void;
  handleDropdown: (e: React.ChangeEvent, dropdownName: any) => void;
}

const ContactInfo = ({
  watch,
  errors,
  handleRegister,
  handleDropdown,
  handlePhoneNumber
}: IContactInfo) => {
  const classes = styles();

  return (
    <div>
      <Heading name="Contact Info" />
      <div className={classes.inputsContainer}>
        {CONTACT_INFO.map(({ id, name, type, label }) =>
          type === 'phoneNumber' ? (
            <CustomInput
              key={id}
              name={name}
              label={label}
              value={watch(name)}
              errorMsg={errors[name]?.message}
              customInputClass={classes.customInput}
              onChange={e => handlePhoneNumber(e, name)}
            />
          ) : (
            <CustomInput
              key={id}
              name={name}
              label={label}
              value={watch(name)}
              {...handleRegister(name)}
              errorMsg={errors[name]?.message}
              customInputClass={classes.customInput}
            />
          )
        )}
        <Dropdown
          multiple
          selectedItem={watch('emergRelation')}
          customSelectClass={classes.customInput}
          label="Emergency Contact Relationship"
          errorMsg={errors?.emergRelation?.message}
          handleItems={e => handleDropdown(e, 'emergRelation')}
        >
          {EMERGENCY_CONTACT_OPTIONS.map(value => (
            <DropdownItem key={value} value={value}>
              {value}
            </DropdownItem>
          ))}
        </Dropdown>
        <CustomInput
          name="emergPhone"
          value={watch('emergPhone')}
          {...handleRegister('emergPhone')}
          customInputClass={classes.customInput}
          errorMsg={errors?.emergPhone?.message}
          label="Emergency Contact Primary Phone"
          onChange={e => handlePhoneNumber(e, 'emergPhone')}
        />
      </div>
    </div>
  );
};

export default ContactInfo;
