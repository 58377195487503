import { gql } from '@apollo/client';

export const GET_ALL_CRITERIA = gql`
  query getAllCriteria($data: SearchCriterionInput!) {
    getAllCriteria(data: $data) {
      data {
        id
        title
        disabled
        description
      }
      count
    }
  }
`;
