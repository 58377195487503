import * as yup from 'yup';

export const criteriaValidationSchema = yup.object().shape({
  title: yup.string().required('Field is required'),
  disabled: yup.string().required('Field is required'),
  description: yup.string().required('Field is required')
});

export const recommendationValidationSchema = yup.object().shape({
  title: yup.string().required('Field is required'),
  disabled: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  details: yup.string().required('Field is required')
});
