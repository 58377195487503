import { ROLES } from '../../constants';

export const mapAddressToRows = (unMappedAddresses, primaryAddressId) =>
  unMappedAddresses?.map(address => ({
    ...address,
    status: address?.id === primaryAddressId ? 'Primary' : '',
    address: `${address?.address1} ${address?.address2}`
  }));

export const mapStaffToRows = (
  unMappedStaff,
  primaryStaffId,
  primaryStaffSupervisor
) =>
  unMappedStaff?.map(staff => ({
    id: staff?.id,
    status:
      staff?.id === primaryStaffId
        ? 'Primary Staff'
        : staff?.id === primaryStaffSupervisor
        ? 'Primary Staff Supervisor'
        : '',
    name: `${staff?.firstName} ${staff?.lastName}`,
    linkedStatus: 'Linked',
    active: staff?.active,
    role: staff?.role === 'STAFF' ? 'Staff' : 'Staff Supervisor'
  }));

export const getUserRole = user =>
  user?.role === 'Staff' ? ROLES.STAFF : ROLES.STAFF_SUPERVISOR;

export const mapStaffSearchValues = searchValues => {
  const newSearchValues = {
    firstName: searchValues?.firstName,
    lastName: searchValues?.lastName
  };

  if (!searchValues?.firstName) delete newSearchValues.firstName;
  if (!searchValues?.lastName) delete newSearchValues.lastName;

  return newSearchValues;
};

export const mapStaffSearchResults = (unMappedStaff, linkedStaff) =>
  unMappedStaff?.map(staff => ({
    id: staff?.id,
    name: `${staff?.firstName} ${staff?.lastName}`,
    email: staff?.email,
    linkedStatus: linkedStaff?.includes(staff?.id),
    role: staff?.role
      ? staff.role
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : ''
  }));

export const mapPharmacistSearchValues = searchValues => {
  const newSearchValues = {
    firstName: searchValues?.firstName,
    lastName: searchValues?.lastName
  };

  if (!searchValues?.firstName) delete newSearchValues.firstName;
  if (!searchValues?.lastName) delete newSearchValues.lastName;

  return newSearchValues;
};

export const mapPharmacistSearchResults = (
  unMappedPharmacists,
  linkedPharmacist
) =>
  unMappedPharmacists?.map(pharmacist => ({
    id: pharmacist?.id,
    name: `${pharmacist?.firstName} ${pharmacist?.lastName}`,
    email: pharmacist?.email,
    linkedStatus: linkedPharmacist === pharmacist?.id
  }));

export const mapProvidersToSearchTable = (
  unMappedProviders,
  searchValues,
  linkedProviders?
) =>
  unMappedProviders?.map(provider => {
    let providerFirstName =
      provider?.basic?.first_name ||
      provider?.basic?.authorized_official_first_name ||
      '';
    let providerLastName =
      provider?.basic?.last_name ||
      provider?.basic?.authorized_official_last_name ||
      '';
    let providerState = provider?.addresses
      ? provider?.addresses[0]?.state
      : '';
    let providerCity = provider?.addresses ? provider?.addresses[0]?.city : '';
    let providerAddress = provider?.addresses
      ? provider?.addresses[0]?.address_1 || provider?.addresses[0]?.address_2
      : '';
    let providerFax = provider?.addresses
      ? provider?.addresses[0]?.fax_number
      : '';
    let providerZipCode = provider?.addresses
      ? provider?.addresses[0]?.postal_code
      : '';
    let providerPhone = provider?.addresses
      ? provider?.addresses[0]?.telephone_number
      : '';

    if (
      (searchValues?.firstName &&
        !providerFirstName
          ?.toLowerCase()
          .includes(searchValues?.firstName?.toLowerCase()?.trim())) ||
      (searchValues?.lastName &&
        !providerLastName
          ?.toLowerCase()
          .includes(searchValues?.lastName?.toLowerCase()?.trim()))
    ) {
      const isExact = searchValues?.nameFilterType !== 'beginsWith';
      const aliasedProvider = provider?.other_names?.find(otherNameObject =>
        isExact
          ? otherNameObject?.first_name
              ?.toLowerCase()
              .includes(searchValues?.firstName?.toLowerCase()?.trim()) &&
            otherNameObject?.last_name
              ?.toLowerCase()
              .includes(searchValues?.lastName?.toLowerCase()?.trim())
          : otherNameObject?.first_name
              ?.toLowerCase()
              .startsWith(searchValues?.firstName?.toLowerCase()?.trim()) &&
            otherNameObject?.last_name
              ?.toLowerCase()
              .startsWith(searchValues?.lastName?.toLowerCase()?.trim())
      );
      if (aliasedProvider) {
        providerFirstName =
          aliasedProvider?.first_name ||
          aliasedProvider?.authorized_official_first_name;
        providerLastName =
          aliasedProvider?.last_name ||
          aliasedProvider?.authorized_official_last_name;
      }
    }

    if (
      (searchValues?.state && !providerState?.includes(searchValues?.state)) ||
      (searchValues?.city &&
        !providerCity
          ?.toLowerCase()
          .includes(searchValues?.city?.toLowerCase()?.trim()))
    ) {
      const aliasedProviderAddress = provider?.addresses?.find(
        address =>
          address?.state?.includes(searchValues?.state) &&
          address?.city
            ?.toLowerCase()
            .includes(searchValues?.city?.toLowerCase()?.trim()) &&
          address?.postal_code
            ?.toLowerCase()
            ?.startsWith(searchValues?.zip?.trim())
      );
      if (aliasedProviderAddress) {
        providerState = aliasedProviderAddress?.state;
        providerCity = aliasedProviderAddress?.city;
        providerAddress =
          aliasedProviderAddress?.address_1 ||
          aliasedProviderAddress?.address_2;
        providerFax = aliasedProviderAddress?.fax_number;
        providerZipCode = aliasedProviderAddress?.postal_code;
        providerPhone = aliasedProviderAddress?.telephone_number;
      } else {
        const aliasedProviderAddress = provider?.practiceLocations?.find(
          address =>
            address?.state?.includes(searchValues?.state) &&
            address?.city
              ?.toLowerCase()
              .includes(searchValues?.city?.toLowerCase()?.trim()) &&
            address?.postal_code
              ?.toLowerCase()
              ?.startsWith(searchValues?.zip?.trim())
        );

        if (aliasedProviderAddress) {
          providerState = aliasedProviderAddress?.state;
          providerCity = aliasedProviderAddress?.city;
          providerAddress =
            aliasedProviderAddress?.address_1 ||
            aliasedProviderAddress?.address_2;
          providerFax = aliasedProviderAddress?.fax_number;
          providerZipCode = aliasedProviderAddress?.postal_code;
          providerPhone = aliasedProviderAddress?.telephone_number;
        } else {
          const aliasedProviderEndpoint = provider?.endpoints?.find(
            address =>
              address?.state?.includes(searchValues?.state) &&
              address?.city
                ?.toLowerCase()
                .includes(searchValues?.city?.toLowerCase()?.trim()) &&
              address?.postal_code
                ?.toLowerCase()
                ?.startsWith(searchValues?.zip?.trim())
          );

          if (aliasedProviderEndpoint) {
            providerState = aliasedProviderEndpoint?.state;
            providerCity = aliasedProviderEndpoint?.city;
            providerAddress =
              aliasedProviderEndpoint?.address_1 ||
              aliasedProviderEndpoint?.address_2;
            providerFax = aliasedProviderEndpoint?.fax_number;
            providerZipCode = aliasedProviderEndpoint?.postal_code;
            providerPhone = aliasedProviderEndpoint?.telephone_number;
          }
        }
      }
    }

    return {
      id: provider?.number,
      role: provider?.taxonomies
        ? provider?.taxonomies?.find(taxonomy => taxonomy?.primary === true)
            ?.desc
        : '',
      first_name: providerFirstName,
      last_name: providerLastName,
      npi: provider?.number,
      fax: providerFax,
      address: providerAddress,
      city: Number.isNaN(parseInt(providerCity, 10)) ? providerCity : '',
      state: providerState,
      zip: providerZipCode,
      phone: providerPhone,
      linkedStatus: linkedProviders?.includes(provider?.number?.toString())
    };
  });

export const mapPatientProvidersToTable = unMappedProviders =>
  unMappedProviders?.map(provider => ({
    ...provider,
    id: provider?.npi,
    name: `${provider?.firstName} ${provider?.lastName}`
  }));

export const mapAssessmentsTableInputs = (
  patientId,
  offset,
  status,
  sortModel
) => {
  let mappedInputs = {
    offset,
    status: status === 'ALL' ? '' : status,
    patientId,
    limit: 10
  };

  if (sortModel.length > 0) {
    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase(),
      orderBy: 'STATUS'
    };

    mappedInputs = { ...mappedInputs, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(mappedInputs).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapAssessmentsDataToRows = data => {
  const rows = [
    ...data.map(row => ({
      ...row,
      completedAt: row.completedAt
        ? new Date(row.completedAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        : ''
    }))
  ];

  return rows;
};
