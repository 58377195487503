import {
  Packer,
  Document,
  Header,
  Paragraph,
  ImageRun,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  TableLayoutType,
  BorderStyle
} from 'docx';
import header_logo from '../../assets/images/header_logo.png';

const getLogoImage = async () => {
  try {
    const response = await fetch(header_logo);
    const logo = await response.arrayBuffer();
    return logo;
  } catch (e) {
    return null;
  }
};

const getHeader = async () => {
  const logo: ArrayBuffer | null = await getLogoImage();
  let header;
  if (logo) {
    header = new Header({
      children: [
        new Table({
          layout: TableLayoutType.FIXED,
          columnWidths: [10000],
          borders: {
            top: { style: BorderStyle.NONE },
            bottom: { style: BorderStyle.NONE },
            left: { style: BorderStyle.NONE },
            right: { style: BorderStyle.NONE }
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: logo,
                          transformation: { width: 150, height: 40 }
                        })
                      ],
                      alignment: AlignmentType.RIGHT
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new Paragraph(''),
        new Paragraph('')
      ]
    });
  } else {
    header = new Header({
      children: [
        new Paragraph({
          children: [new Paragraph({ text: '' })],
          alignment: AlignmentType.RIGHT
        })
      ]
    });
  }
  return header;
};

export async function handleDownloadDoc(documentBody, fileName, isDocx?) {
  const doc = new Document({
    styles: {},
    sections: [
      {
        headers: { default: await getHeader() },
        ...documentBody
      }
    ]
  });
  const buffer = await Packer.toBuffer(doc);
  const blob = new Blob([buffer], {
    type: isDocx
      ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      : 'application/msword'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.${isDocx ? 'docx' : 'doc'}`;
  link.click();
}
