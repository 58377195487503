import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import { styles } from './styles';
import { GET_USER } from '../../graphQL/queries';

interface IProfilePhoto {
  small?: boolean;
  localPhoto?: string;
}

const ProfilePhoto = ({ small, localPhoto }: IProfilePhoto) => {
  const classes = styles();
  const nullPhoto = 'new update photo url';

  const { data: userInfo } = useQuery(GET_USER, {
    onCompleted: data => localStorage.setItem('userId', data.getUser.id)
  });
  const photo = userInfo?.getUser?.photo;
  const isPhoto = photo && photo !== nullPhoto;

  return (
    <div
      className={classNames(
        classes.container,
        { [classes.smallContainer]: small },
        {
          [classes.photoContainer]: isPhoto || localPhoto
        },
        {
          [classes.smallPhotoContainer]: small && (isPhoto || localPhoto)
        }
      )}
    >
      {(localPhoto || isPhoto) && (
        <img
          src={localPhoto || photo}
          className={classNames(classes.photo, { [classes.smallPhoto]: small })}
        />
      )}

      {!localPhoto && !isPhoto && (
        <span>
          {(userInfo?.getUser?.firstName ?? '')?.charAt(0)?.toUpperCase()}
          {(userInfo?.getUser?.lastName ?? '')?.charAt(0)?.toUpperCase()}
        </span>
      )}
    </div>
  );
};

export default ProfilePhoto;
