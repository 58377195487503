import { gql } from '@apollo/client';

export const GET_PATIENT_ASSESSMENTS = gql`
  query getPatientAssessments($data: ListPatientAssessmentsInput!) {
    getPatientAssessments(data: $data) {
      data {
        id
        originalAssessmentId
        processingAssessmentId
        status
        assignedTo
        patient {
          assignedUsers {
            id
          }
        }
        assignedStaff {
          firstName
          lastName
        }
        alertsCount
        publisher {
          firstName
          lastName
          updatedAt
        }
        pharmacists {
          firstName
          lastName
          updatedAt
        }
        contributors {
          firstName
          lastName
          updatedAt
        }
        completedAt
      }
      count
    }
  }
`;
