import classNames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { styles } from './styles';
import { WarningIcon, DatePickerIcon } from '../../assets/icons';

interface IDatePicker {
  name?: string;
  format?: string;
  errorMsg?: string;
  customClass?: any;
  end?: ParsableDate;
  placeholder?: string;
  value: string | null;
  start?: ParsableDate;
  handleDateChange: any;
  styleGreyBorder?: boolean;
  styleShortInput?: boolean;
  labelComponent?: JSX.Element;
}

const DatePicker = ({
  end,
  name,
  start,
  value,
  format,
  errorMsg,
  placeholder,
  customClass,
  labelComponent,
  styleGreyBorder,
  handleDateChange,
  styleShortInput
}: IDatePicker) => {
  const classes = styles();

  return (
    <div>
      {labelComponent}
      <KeyboardDatePicker
        autoOk
        value={value}
        variant="inline"
        format={format || 'MM/dd/yyyy'}
        minDate={start || '01/01/1900'}
        maxDate={end || new Date()}
        inputVariant="outlined"
        className={classNames(
          classes.datePicker,
          { [classes.greyBorder]: styleGreyBorder },
          { [classes.errorBorder]: errorMsg },
          { [classes.shortInput]: styleShortInput },
          { [customClass]: customClass }
        )}
        keyboardIcon={<DatePickerIcon />}
        placeholder={placeholder || 'MM/DD/YYYY'}
        InputAdornmentProps={{ position: 'start' }}
        onChange={date => handleDateChange(name, date)}
      />
      {errorMsg && (
        <label className={classes.errorText}>
          <WarningIcon className={classes.warningLogo} />
          {errorMsg}
        </label>
      )}
    </div>
  );
};

export default DatePicker;
