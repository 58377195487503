import { GridColDef } from '@material-ui/data-grid';

import { dashboardRecordsModel } from '../../models';
import { ALL_PERMISSIONS } from './roles-permissions';

export const STAFF_SEARCH_RESULTS = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Smith',
    site: 'TBD 3',
    email: 'john@gmail.com',
    action: false
  },
  {
    id: 2,
    firstName: 'John',
    lastName: 'Doe',
    site: 'TBD 4',
    email: 'joker@gmail.com',
    action: false
  }
];

function getFullName(params) {
  return `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }`;
}

export const STAFF_SEARCH_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    sortable: false,
    hide: true
  },
  {
    field: 'firstName',
    headerName: 'Name',
    sortable: false,
    flex: 0,
    hide: true
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    sortable: false,
    flex: 0,
    hide: true
  },
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1,
    valueGetter: getFullName,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      getFullName(cellParams1).localeCompare(getFullName(cellParams2))
  },
  {
    field: 'site',
    headerName: 'Site',
    sortable: false,
    flex: 0,
    hide: true
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortable: false
  }
];

export const STAFF_OWNER_FILTER = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Any' }
];

export const PHARMACIST_OWNER_FILTER = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Any' }
];

export const ASSESSMENT_STATUS_FILTER = [
  { id: 1, name: 'All', value: 'All' },
  { id: 2, name: 'Completed', value: 'COMPLETED' },
  { id: 3, name: 'Processing', value: 'PROCESSING' },
  { id: 4, name: 'Draft', value: 'DRAFT' },
  { id: 5, name: 'Published', value: 'PUBLISHED' }
];

export const DASHBOARD_STATS = [
  {
    id: 1,
    field: 'assessmentsIncomplete',
    name: 'Assessments Incomplete',
    permission: ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENT_INCOMPLETE
  },
  {
    id: 2,
    field: 'assessmentsIncomplete',
    name: 'Assessments To Do',
    permission: ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENTS_TO_DO
  },
  {
    id: 3,
    field: 'patientsWithAlertsUnresolved',
    name: 'Patients with Unresolved Alerts'
  },
  {
    id: 4,
    field: 'staffMembers',
    name: 'Staff Members',
    permission: ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_STAFF_MEMBERS
  }
];

export const DASHBOARD_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true, flex: 1 },
  { field: 'name', headerName: 'Name', flex: 0.9 },
  { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'assessmentStatus', headerName: 'Assessment Status', flex: 1.1 },
  { field: 'alertStatus', headerName: 'Alert Status', flex: 1 },
  { field: 'pharmacist', headerName: 'Pharmacist', flex: 0.8 },
  { field: 'staff', headerName: 'Staff', flex: 0.7 },
  { field: 'secondaryPharmacist', headerName: 'Secondary Pharmacist', flex: 1 }
];

export const DASHBOARD_ROWS: dashboardRecordsModel = [
  {
    id: 1,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 2,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Incomplete',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: null,
    secondaryPharmacist: null
  },
  {
    id: 3,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Incomplete',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: null,
    secondaryPharmacist: null
  },
  {
    id: 4,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 5,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Incomplete',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: null,
    secondaryPharmacist: null
  },
  {
    id: 6,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 7,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Incomplete',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 8,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 9,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 10,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 0,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 11,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 12,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 13,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 14,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 15,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 16,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 17,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 18,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 19,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 20,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 21,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Completed',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  },
  {
    id: 22,
    name: 'Last, First',
    address: '1234 Washington Blvd, Culver City, CA 90232',
    phoneNumber: 'XXX-XXX-XXXX',
    email: 'user@email.com',
    assessmentStatus: 'Incomplete',
    alertStatus: 2,
    pharmacist: 'First Last',
    staff: 'First Last',
    secondaryPharmacist: 'First Last'
  }
];
