import { gql } from '@apollo/client';

export const SEARCH_DASHBOARD_SECONDARY_PHARMACIST = gql`
  query searchDashboardSecondaryPharmacist($data: PatientDashboardInput!) {
    patientDashboard(data: $data) {
      data {
        patientId
        patientName
        email
        phonenumber
        alertsCount
        assessmentId
        staffName
        processingAssessmentId
        assessmentStatus
      }
      count
      patientsWithAlertsUnresolved
    }
  }
`;
