import { Switch, Redirect } from 'react-router-dom';

import Header from './header';
import Footer from './footer';
import { styles } from './styles';
import { PATH_NAME } from '../../routes/pathNames';
import {
  Faq,
  Privacy,
  Sites,
  Alerts,
  People,
  Patient,
  Mapping,
  Configure,
  Reporting,
  Dashboard,
  Assessment,
  Pharmacies,
  PatientReport,
  Recommendation,
  AccountSettings,
  ManageCriterion,
  UnderConstruction,
  ComprehensiveReport
} from '../../routes/userRoutes';

const HomeRepresentation = () => {
  const classes = styles();

  return (
    <main className={classes.root}>
      <Header />
      <main className={classes.container}>
        <Switch>
          {Faq()}
          {Privacy()}
          {Sites()}
          {People()}
          {Alerts()}
          {Patient()}
          {Mapping()}
          {Configure()}
          {Reporting()}
          {Dashboard()}
          {Assessment()}
          {Pharmacies()}
          {PatientReport()}
          {Recommendation()}
          {ManageCriterion()}
          {AccountSettings()}
          {UnderConstruction()}
          {ComprehensiveReport()}

          <Redirect to={PATH_NAME.DASHBOARD} />
        </Switch>

        <Footer />
      </main>
    </main>
  );
};

export default HomeRepresentation;
