export const prepareAssesmentDrugsSubmit = (
  unMappedDrug,
  oldDrugs,
  providers,
  pharmacies,
  originalEditId
) => {
  const {
    id,
    name,
    route,
    asNeeded,
    dosage,
    quantity,
    frequency,
    directions,
    dispenseDate,
    firstUseDate,
    providerId,
    pharmacyId,
    notes
  } = unMappedDrug;

  const providerName = `${
    providers?.find(provider => provider?.npi === providerId)?.firstName || ''
  } ${
    providers?.find(provider => provider?.npi === providerId)?.lastName || ''
  }`;

  const mappedDrug = {
    id,
    name,
    route,
    asNeeded: asNeeded && asNeeded === 'Yes',
    dosage: dosage[0] ? parseFloat(dosage[0]) : '',
    dosageMeasurement: dosage[1],
    quantity: quantity[0] ? parseFloat(quantity[0]) : '',
    quantityMeasurement: quantity[1] ? quantity[1] : '',
    frequency,
    direction: directions,
    dispenseDate,
    firstUseDate,
    notes,
    providerId: providerId === 'N/A' ? '' : providerId,
    pharmacyId: pharmacyId === 'N/A' ? '' : pharmacyId,
    providerName: providerName === ' ' ? '' : providerName ?? '',
    pharmacyName:
      pharmacies?.find(pharmacy => pharmacy?.npi === pharmacyId)?.name || ''
  };

  const newDrug = Object.fromEntries(
    Object.entries(mappedDrug).filter(([_, v]) => v != null && v !== '')
  );

  const isEdittingOtherValues = oldDrugs?.some(drug => drug?.name === name);
  let newDrugs: any[] = [];
  // Editing other values
  if (isEdittingOtherValues)
    newDrugs = oldDrugs
      ?.map(drug => {
        if (drug?.name === name) return newDrug;
        return drug;
      })
      .map(({ __typename, ...drug }) => drug);
  // Editing the name
  else if (originalEditId && originalEditId === newDrug?.id) {
    newDrugs = oldDrugs
      ?.map(drug => {
        if (drug?.id === originalEditId) return newDrug;
        return drug;
      })
      .map(({ __typename, ...drug }) => drug);
    // Adding a new one
  } else {
    const preparedOldDrugs = oldDrugs.map(({ __typename, ...drug }) => drug);

    newDrugs = [...preparedOldDrugs, newDrug];
  }

  return newDrugs?.map(({ __typename, ...rest }) => rest);
};
