import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    loaderContainer: {
      position: 'relative',
      width: '100%',
      height: '80vh'
    },
    patientContainer: {
      paddingBottom: 48
    },
    patientTabsContainer: {
      maxWidth: theme.lengths.mainContainerWidth,
      margin: '0 auto'
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: 32
    },
    assessmentsTabs: {
      marginTop: -7,
      marginBottom: 16
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: 8
      },
      '& > :last-child': {
        marginRight: 0
      }
    },
    patientInfo: {
      '& > *': {
        marginTop: 54
      },
      '& > :first-child': {
        marginTop: 24
      }
    },
    assessmentTitle: {
      marginTop: 38,
      marginBottom: 16,
      color: theme.colors.text,
      fontSize: 18,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '24px'
    },
    tableWrapper: {
      marginTop: 8,
      '& .action svg': {
        cursor: 'pointer'
      }
    },
    tableWithOptions: {
      '& .MuiDataGrid-row > .MuiDataGrid-cell:last-of-type > div': {
        marginTop: '8px !important'
      }
    },
    customTable: {
      '& .MuiDataGrid-cell': {
        borderBottom: `1px solid rgba(224, 224, 224, 1) !important`
      },
      '& .MuiDataGrid-row': {
        marginLeft: 8,
        '&:hover': {
          backgroundColor: 'rgba(237, 243, 248, 0.5)'
        }
      },
      '& .MuiDataGrid-window': {
        overflowX: 'hidden !important'
      }
    },
    customAssessmentTable: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important'
      }
    },
    participantsWrapper: {
      padding: '6px 0'
    },
    participant: {
      lineHeight: '30px'
    },
    contributorName: {
      fontWeight: 'bold'
    },
    reportsWrapper: {
      display: 'flex',
      '& > :first-child': {
        marginRight: 9
      }
    },
    tag: {
      padding: '3px 6.5px',
      color: theme.colors.primary,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: 8,
      fontSize: 13,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px',
      cursor: 'pointer'
    },
    alert: {
      color: theme.colors.error,
      backgroundColor: 'rgba(192, 23, 34, 0.1)',
      border: `1px solid ${theme.colors.error}`
    },
    alertsResolved: {
      color: '#00A984',
      backgroundColor: 'white',
      border: `1px solid #00A984`
    },
    status: {
      width: 86,
      height: 26,
      paddingTop: 3,
      textAlign: 'center',
      color: theme.colors.white,
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px'
    },
    publishedAssessment: {
      backgroundColor: theme.colors.green
    },
    completedassessment: {
      backgroundColor: theme.colors.primary
    },
    draftAssessment: {
      backgroundColor: theme.colors.greyBackground
    },
    customOptions: {
      marginTop: '-16px !important'
    },
    drafts: {
      marginTop: -8,
      color: theme.colors.primary,
      fontSize: 15,
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px',
      cursor: 'pointer'
    },
    tooltipOverflow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    optionsContainer: {
      '& > svg:first-of-type': {
        marginRight: '4px'
      },
      '& > svg:last-of-type': {
        marginTop: '0px',
        marginBottom: '12px',
        marginRight: '7px'
      }
    },
    editWrapper: {
      display: 'flex'
    },
    customModal: {
      width: 1024,
      height: 600
    },
    truncatedTableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
);
