import { getFormattedDate, validatePhoneNumber } from '../../helpers';

export const mapUserToViewModel = (user, peopleType) => {
  if (user?.role === 'PATIENT') {
    return {
      id: user?.id,
      assignSite: localStorage.getItem('defaultSiteId') as string | undefined,
      userType: 'Patients',
      firstName: user?.firstName,
      lastName: user?.lastName,
      activeStatus: user?.active ? 'Active' : 'Inactive',
      dateOfBirth: getFormattedDate(new Date(user?.date))
    };
  }
  const userType = peopleType?.find(type => type?.id === user?.role)?.name;
  return {
    id: user?.id,
    userType,
    assignSite: localStorage.getItem('defaultSiteId') as string | undefined,
    firstName: user?.firstName,
    lastName: user?.lastName,
    activeStatus: user?.active ? 'Active' : 'Inactive',
    email: user?.email,
    phoneNumber: validatePhoneNumber(user?.phonenumber || ''),
    phoneExtension: user?.extension || ''
  };
};
