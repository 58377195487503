import { useQuery } from '@apollo/client';
import { Redirect, Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from './pathNames';
import { ROLES } from '../utils/constants';
import { permissionModel } from '../models';
import { GET_LOCAL_STATES } from '../graphQL/queries';

interface Props {
  path: string;
  exact?: boolean;
  permission: permissionModel;
  component: (props: JSX.IntrinsicAttributes) => JSX.Element;
}

const AdminRoute = ({ path, exact, component, permission }: Props) => {
  const {
    data: {
      localStates: { userPermissions, userRole }
    }
  } = useQuery(GET_LOCAL_STATES);

  const couldShow: boolean = userPermissions.includes(permission);

  if (couldShow)
    return <PublicRoute path={path} exact={exact} component={component} />;

  return (
    <Redirect
      to={
        userRole === ROLES.ANALYST ? PATH_NAME.REPORTING : PATH_NAME.DASHBOARD
      }
    />
  );
};

export default AdminRoute;
