import { useRef, useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Typography, TextField } from '@material-ui/core';

import { styles } from './styles';
import Loader from '../../../../sharedComponents/Loader';
import { PATH_NAME } from '../../../../routes/pathNames';
import { ClearIcon, SearchIcon } from '../../../../assets/icons';
import { GLOBAL_NAVIGATION_PATIENTS_SEARCH } from '../../../../graphQL/queries';

const HeaderSearchInput = () => {
  const classes = styles();
  const history = useHistory();
  const searchRef = useRef<HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [searchPatients, { data, called, loading }] = useLazyQuery(
    GLOBAL_NAVIGATION_PATIENTS_SEARCH,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setSearchResults(data?.globalNavigationPatientsSearch?.data);
      },
      onError: () => {
        setSearchResults([]);
      }
    }
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue?.length > 1) {
        searchPatients({
          variables: {
            data: {
              searchString: searchValue,
              limit: 10
            }
          }
        });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleClearTextValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleClickSearchResult = (id: any) => {
    history.push(PATH_NAME.PATIENT.replace(':id', id));
  };

  useEffect(() => {
    const clearRef = searchRef?.current;
    if (clearRef !== null) {
      const close = clearRef.getElementsByClassName(
        'MuiAutocomplete-clearIndicator'
      )[0];
      if (close !== null) {
        close.addEventListener('click', () => handleClearTextValue());
      }
      return () => {
        if (close !== null) {
          close.removeEventListener('click', () => handleClearTextValue());
        }
      };
    }
  }, [searchRef, handleClearTextValue]);

  const navigateToPeoplePage = (e, searchButton?: boolean) => {
    if (called && !loading)
      if (e.keyCode === 13 || searchButton) {
        history.push({
          pathname: PATH_NAME.PEOPLE,
          state: {
            searchValue,
            searchResults,
            count: data?.globalNavigationPatientsSearch?.count
          }
        });
      }
  };

  return (
    <>
      <SearchIcon
        className={classes.searchIcon}
        onClick={e => navigateToPeoplePage(e, true)}
      />
      <Autocomplete
        freeSolo
        ref={searchRef}
        inputValue={searchValue}
        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
        classes={{
          popper: classes.searchPopper,
          inputRoot: classes.searchFilter,
          endAdornment: classes.searchEndAdornment
        }}
        closeIcon={
          searchValue?.length > 0 ? (
            <ClearIcon className={classes.clearIcon} />
          ) : null
        }
        options={
          searchValue?.length > 1 && searchResults?.length > 0 && !loading
            ? searchResults
            : []
        }
        PaperComponent={({ children }: any) => (
          <Paper
            className={classNames(classes.optionsPaper, {
              [classes.seeMoreOptionsPaper]:
                searchValue?.length > 1 &&
                data?.globalNavigationPatientsSearch?.count > 10
            })}
          >
            {loading ? (
              <div className={classes.loadingPaper}>
                <Loader />
              </div>
            ) : (
              children
            )}
          </Paper>
        )}
        filterOptions={options => {
          const result = [...options];
          if (
            searchValue?.length > 1 &&
            data?.globalNavigationPatientsSearch?.count > 10
          ) {
            result.push({
              firstName: 'See',
              lastName: 'More',
              type: 'seeMore'
            } as unknown as string);
          }

          return result;
        }}
        renderOption={({ id, firstName, lastName, type }) => (
          <Typography
            className={classNames(classes.searchOptions, {
              [classes.seeMoreOption]: type === 'seeMore'
            })}
            onClick={
              type !== 'seeMore'
                ? () => handleClickSearchResult(id)
                : e => navigateToPeoplePage(e, true)
            }
          >
            {firstName} {lastName}
          </Typography>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Search Patients"
            className={classes.searchText}
            onKeyDown={navigateToPeoplePage}
            onChange={e => setSearchValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        )}
      />
    </>
  );
};

export default HeaderSearchInput;
