import { generateHexString } from '../../helpers';

export const mapTenMedsChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapTenMedsTableInputs = (
  createdFrom,
  createdTo,
  tenMedsOrMore,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    tenMedsOrMore
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME',
      age: 'AGE',
      fall: 'FALL',
      dizzy: 'DIZZY',
      hospitalized: 'HOSPITALIZED'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapTenMedsOutputsToChartData = (results?) => {
  const tenOrMoreColor = `#${generateHexString(6)}`;
  const lessThanTenColor = `#${generateHexString(6)}`;

  let chartData = [
    {
      name: 'Ten Meds or More',
      patientsCount: 0,
      color: tenOrMoreColor
    },
    { name: 'Less than Ten Meds', patientsCount: 0, color: lessThanTenColor }
  ];

  if (results) {
    chartData = [
      {
        name: 'Ten Meds or More',
        patientsCount: results?.tenMedsOrMore || 0,
        color: tenOrMoreColor
      },
      {
        name: 'Less than Ten Meds',
        patientsCount: results?.lessThanTen || 0,
        color: lessThanTenColor
      }
    ];
  }

  return chartData;
};

export const mapTenMedsOutputsToTable = patients =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId
  }));

export const mapTenMedsExportedRowsToCSV = exportedRows =>
  exportedRows?.map(row => ({
    ...row,
    fall: row?.fall ? 'Yes' : 'No',
    dizzy: row?.dizzy ? 'Yes' : 'No',
    hospitalized: row?.hospitalized ? 'Yes' : 'No'
  }));
