import { generateHexString } from '../../helpers';

export const mapAlertsGeneratedChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapAlertsGeneratedTableInputs = (
  createdFrom,
  createdTo,
  criterionId,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    criterionId
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME',
      age: 'AGE'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapAlertsGeneratedOutputsToChartData = (siteId, results?) =>
  results?.criterion?.map(criterion => ({
    name: criterion.title,
    id: criterion.id,
    alertsCount: criterion?.count ?? 0,
    color: `#${generateHexString(6)}`
  }));

export const mapAlertsGeneratedOutputsToTable = (patients, alertType) =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId,
    date: patient?.lastGeneratedDate
      ? new Date(patient?.lastGeneratedDate as string)?.toLocaleDateString()
      : '',
    alertType
  }));

export const mapAlertsGeneratedOutputsToExportedTable = chartData =>
  chartData?.criterion?.map(record => ({
    ...record,
    alertType: record?.title,
    totalPatients: chartData?.totalPatients
  }));
