import { styles } from '../styles';
import ContactInfo from './contactInfo';
import PersonalInfo from './personalInfo';
import Demographics from './demographics';
import Tab from '../../../sharedComponents/tab';
import { PATIENTS_TABS, ROLES } from '../../../utils/constants';
import CustomModal from '../../../sharedComponents/customModal';
import CustomButton from '../../../sharedComponents/customButton';
import BlockRouting from '../../../sharedComponents/blockRouting';
import ModalConfirmBody from '../../../sharedComponents/modalConfirmBody';
import ToastMessage from '../../../sharedComponents/toastMessage';

interface IPatientFormRepresentation {
  watch: any;
  errors: any;
  open: boolean;
  userRole: string;
  isDirty: boolean;
  handleSubmit: any;
  activeTab?: number;
  handleRegister: any;
  isConfirmed: boolean;
  isSubmitted: boolean;
  handleFormSubmit: any;
  isErrorToastOpen: boolean;
  handleUnblock: () => void;
  handleToastClose: () => void;
  handleModalClose: () => void;
  handleErrorToastClose: () => void;
  handleActiveTab: (id: number) => void;
  handleDateChange: (name: string, date: any) => void;
  handlePhoneNumber: (e: any, fieldName: any) => void;
  handleDropdown: (e: React.ChangeEvent, dropdownName: any) => void;
}

const PatientFormRepresentation = ({
  open,
  watch,
  errors,
  isDirty,
  userRole,
  activeTab,
  isSubmitted,
  isConfirmed,
  handleSubmit,
  handleUnblock,
  handleRegister,
  handleDropdown,
  handleActiveTab,
  isErrorToastOpen,
  handleDateChange,
  handleFormSubmit,
  handleModalClose,
  handleToastClose,
  handlePhoneNumber,
  handleErrorToastClose
}: IPatientFormRepresentation) => {
  const classes = styles();

  return (
    <BlockRouting isDirty={isDirty} isSubmitted={isSubmitted}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <ToastMessage
          open={isErrorToastOpen}
          handleClose={handleErrorToastClose}
          variant="error"
          message="You need to add at least one (1) address to the “Address” field to save a patient"
        />
        <ToastMessage
          open={isConfirmed}
          handleClose={handleToastClose}
          message="Patient profile saved successfully!"
        />
        <div className={classes.tabsContainer}>
          <div className={classes.tabs}>
            {PATIENTS_TABS.map(({ id, name }) => (
              <Tab
                key={id}
                name={name}
                active={activeTab === id}
                onClick={() => handleActiveTab(id)}
              />
            ))}
          </div>
          {userRole !== ROLES.ANALYST && (
            <CustomButton type="submit">Save</CustomButton>
          )}
        </div>
        <div className={classes.patientInfo}>
          <PersonalInfo
            watch={watch}
            errors={errors}
            handleRegister={handleRegister}
            handleDropdown={handleDropdown}
            handleDateChange={handleDateChange}
          />
          <ContactInfo
            watch={watch}
            errors={errors}
            handleRegister={handleRegister}
            handleDropdown={handleDropdown}
            handlePhoneNumber={handlePhoneNumber}
          />
          <Demographics
            watch={watch}
            errors={errors}
            handleDropdown={handleDropdown}
          />
        </div>
      </form>
      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        modalTitle="Unsaved changes"
        handleModalClose={handleModalClose}
        modalBody={
          <ModalConfirmBody
            prompt="Your changes have not been saved. Are you sure you want to leave
              the page?"
            confirmText="No"
            handleConfirm={handleModalClose}
            cancelText="Yes"
            handleCancel={handleUnblock}
          />
        }
      />
    </BlockRouting>
  );
};

export default PatientFormRepresentation;
