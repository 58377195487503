import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { Color, Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { styles } from './styles';

interface Props {
  open: boolean;
  message: string;
  autoHideDuration?: number;
  variant?: Color | undefined;
  handleClose:
    | ((
        event: React.SyntheticEvent<any, Event>,
        reason?: SnackbarCloseReason
      ) => void)
    | undefined;
}

const ToastMessage = ({
  autoHideDuration = 3000,
  variant = 'info',
  handleClose,
  message,
  open
}: Props) => {
  const classes = styles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      open={open}
    >
      <Alert
        classes={{
          root: classNames(classes.confirmationMessage, {
            [classes.infoMessage]: variant === 'info',
            [classes.errorMessage]: variant === 'error'
          })
        }}
        onClose={handleClose}
        severity={variant}
        variant="filled"
        action={<></>}
        icon={false}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;
