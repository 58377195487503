import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query searchUsers($data: SearchUsersInput!) {
    searchUsers(data: $data) {
      data {
        id
        firstName
        lastName
        email
        phonenumber
        extension
        photo
        active
        notActivatedYet
        siteTitles
      }
      count
    }
  }
`;
