import { PATH_NAME } from '../../routes/pathNames';
import { ALL_PERMISSIONS } from './roles-permissions';

export const HEADER_LIST = [
  {
    id: 1,
    name: 'Dashboard',
    url: PATH_NAME.DASHBOARD,
    permission: ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD
  },
  { id: 2, name: 'People', url: PATH_NAME.PEOPLE },
  {
    id: 3,
    name: 'Reporting',
    url: PATH_NAME.REPORTING,
    permission: ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE
  }
];

export const HEADER_ADMIN_LIST = [
  {
    id: 1,
    name: 'Manage Criteria',
    url: PATH_NAME.MANAGE_CRITERION,
    permission: ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CRITERION_PAGE
  },
  { id: 2, name: 'Configure', url: PATH_NAME.CONFIGURE },
  {
    id: 3,
    name: 'Sites',
    url: PATH_NAME.SITES,
    permission: ALL_PERMISSIONS.CAN_ACCESS_SITES_PAGE
  },
  { id: 4, name: 'Pharmacies', url: PATH_NAME.PHARMACIES }
];

export const HEADER_PROFILE_LIST = [
  { id: 1, name: 'Account', url: PATH_NAME.ACCOUNT },
  // { id: 2, name: 'Security Questions', url: PATH_NAME.SECURITY },
  { id: 3, name: 'Logout' }
];

export const FOOTER_NAV_LIST = [
  {
    id: 1,
    name: 'Privacy Policy',
    // url: 'https://docs.google.com/document/d/1vH8LtdPbeh90wYkK6z9uMYScFTc6kQvf/edit?usp=sharing&ouid=105485689470736268573&rtpof=true&sd=true',
    // url: 'https://www.picf.org/privacy-policy/',
    url: PATH_NAME.PRIVACY
  },
  {
    id: 2,
    name: 'Terms of Use',
    url: 'https://www.picf.org/innovations-at-work/medication-management/homemeds/terms-of-use/',
    target: '_blank'
  },
  {
    id: 3,
    name: 'FAQ',
    url: PATH_NAME.Faq
  }
];
