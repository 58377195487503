import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { styles } from './styles';
import { ROLES } from '../../../../utils/constants';
import { stopRefresh } from '../../../patient/patientForm';
import Dropdown from '../../../../sharedComponents/dropdown';
import CustomModal from '../../../../sharedComponents/customModal';
import DropdownItem from '../../../../sharedComponents/dropdownItem';
import { siteModel, sitesModel } from '../../../../models/rolesPermissions';
import { CheckmarkIcon, ArrowDownSiteIcon } from '../../../../assets/icons';
import ModalConfirmBody from '../../../../sharedComponents/modalConfirmBody';
import { GET_ALL_SITES, GET_LOCAL_STATES } from '../../../../graphQL/queries';

const SitesDropdown = () => {
  const classes = styles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [tempSiteId, setTempSiteId] = useState<any>(null);
  const { data } = useQuery(GET_ALL_SITES, {
    fetchPolicy: 'cache-and-network'
  });
  const [sitesCount, setSitesCount] = useState(0);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [sites, setSites] = useState<sitesModel>(
    JSON.parse(localStorage.getItem('sites') as string) as sitesModel
  );

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const {
    data: {
      localStates: { userRole }
    }
  } = useQuery(GET_LOCAL_STATES);

  useEffect(() => {
    if (data) {
      setSites(data?.getAllSites);
      if (
        !localStorage.getItem('defaultSiteId') &&
        data?.getAllSites[0].role === ROLES.GLOBAL_ADMIN
      )
        setSelectedSiteId('all_sites');
      else
        setSelectedSiteId(
          localStorage.getItem('defaultSiteId') ||
            (userRole === ROLES.GLOBAL_ADMIN && 'all_sites') ||
            data?.getAllSites[0]?.id ||
            ''
        );

      let totalCount = 0;
      data?.getAllSites?.map(site => {
        totalCount += 1 + (+site?.children?.length || 0);
      });

      setSitesCount(totalCount);
    }
  }, [data]);

  const handleSite = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!stopRefresh()) {
      setSelectedSiteId(event.target.value as string);
      localStorage.setItem('defaultSiteId', event.target.value as string);

      data?.getAllSites?.map(({ id, children, role }) => {
        if (id === (event.target.value as string)) {
          localStorage.setItem('role', role);
        } else if (children) {
          children.map(({ id: childId, role: childRole }) => {
            if (childId === (event.target.value as string)) {
              localStorage.setItem('role', childRole);
            }
          });
        }
      });

      history.go(0);
    } else {
      handleModalOpen();
      setTempSiteId(event.target.value as string);
    }
  };

  const handleUnblock = () => {
    stopRefresh(false);
    handleModalClose();
    setSelectedSiteId(tempSiteId);
    localStorage.setItem('defaultSiteId', tempSiteId);

    data?.getAllSites?.map(({ id, children, role }) => {
      if (id === tempSiteId) {
        localStorage.setItem('role', role);
      } else if (children) {
        children.map(({ id: childId, role: childRole }) => {
          if (childId === tempSiteId) {
            localStorage.setItem('role', childRole);
          }
        });
      }
    });

    history.go(0);
  };

  return (
    <>
      <Dropdown
        styleNoBorder
        handleItems={handleSite}
        Icon={ArrowDownSiteIcon}
        selectedItem={selectedSiteId}
        multiple={
          sites &&
          (sitesCount > 1 ||
            (sitesCount === 1 && userRole === ROLES.GLOBAL_ADMIN))
        }
        customMenuClass={classes.customMenuSite}
        customSelectClass={classes.customDropdownSite}
      >
        {userRole === ROLES.GLOBAL_ADMIN && (
          <DropdownItem value="all_sites">
            All Sites
            {selectedSiteId === 'all_sites' && (
              <CheckmarkIcon className={classes.checkmarkIcon} />
            )}
          </DropdownItem>
        )}
        {sites &&
          sites.map(({ id, title, children }: siteModel) => {
            if (children) {
              const parentChildrenItems: JSX.Element[] = [];
              parentChildrenItems.push(
                <DropdownItem key={id} value={id} tooltipTitle={title}>
                  {title}
                  {id === selectedSiteId && (
                    <CheckmarkIcon className={classes.checkmarkIcon} />
                  )}
                </DropdownItem>
              );
              children.map(({ id: childId, title: childTitle }: siteModel) =>
                parentChildrenItems.push(
                  <DropdownItem
                    childItem
                    key={childId}
                    value={childId}
                    tooltipTitle={childTitle}
                  >
                    {childTitle}
                    {childId === selectedSiteId && (
                      <CheckmarkIcon className={classes.checkmarkIcon} />
                    )}
                  </DropdownItem>
                )
              );
              return parentChildrenItems;
            }
            return (
              <DropdownItem key={id} value={id} tooltipTitle={title}>
                {title}
                {id === selectedSiteId && (
                  <CheckmarkIcon className={classes.checkmarkIcon} />
                )}
              </DropdownItem>
            );
          })}
      </Dropdown>
      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        modalTitle="Unsaved changes"
        handleModalClose={handleModalClose}
        modalBody={
          <ModalConfirmBody
            prompt="Your changes have not been saved. Are you sure you want to leave
              the page?"
            confirmText="No"
            handleConfirm={handleModalClose}
            cancelText="Yes"
            handleCancel={handleUnblock}
          />
        }
      />
    </>
  );
};

export default SitesDropdown;
