import { generateHexString } from '../../helpers';

const getRace: { [key: string]: string } = {
  'American Indian or Alaska Native': 'AMERICAN_OR_ALASKA',
  Asian: 'ASIAN',
  'Black or African American': 'BLACK_OR_AFRICAN',
  White: 'WHITE',
  'Native Hawaiian or other Pacific Islander': 'HAWAIIAN_OR_PACIFIC',
  'Two or more races': 'TWO_OR_MORE',
  'Prefer not to disclose': 'PREFER_NOT_TO_DISCLOSE'
};

const getRaceLabel: { [key: string]: string } = {
  AMERICAN_OR_ALASKA: 'American Indian or Alaska Native',
  ASIAN: 'Asian',
  BLACK_OR_AFRICAN: 'Black or African American',
  WHITE: 'White',
  HAWAIIAN_OR_PACIFIC: 'Native Hawaiian or other Pacific Islander',
  TWO_OR_MORE: 'Two or more races',
  PREFER_NOT_TO_DISCLOSE: 'Prefer not to disclose'
};

export const mapRaceChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapRaceTableInputs = (createdFrom, createdTo, race, sortModel) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    race: getRace[race]
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      name: 'PATIENT_NAME',
      age: 'AGE',
      site: 'SITE_NAME',
      assignedStaff: 'PRIMARY_STAFF_NAME',
      race: 'RACE'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapRaceOutputsToChartData = (siteId, results?) => {
  const AmericanOrAlaskaColor = `#${generateHexString(6)}`;
  const AsianColor = `#${generateHexString(6)}`;
  const BlackOrAfricanColor = `#${generateHexString(6)}`;
  const WhiteColor = `#${generateHexString(6)}`;
  const HawaiianOrPacificColor = `#${generateHexString(6)}`;
  const TwoOrMoreColor = `#${generateHexString(6)}`;
  const prefetNotToDisclose = `#${generateHexString(6)}`;

  let chartData = [
    {
      name: 'American Indian or Alaska Native',
      patientsCount: 0,
      color: AmericanOrAlaskaColor
    },
    { name: 'Asian', patientsCount: 0, color: AsianColor },
    {
      name: 'Black or African American',
      patientsCount: 0,
      color: BlackOrAfricanColor
    },
    { name: 'White', patientsCount: 0, color: WhiteColor },
    {
      name: 'Native Hawaiian or other Pacific Islander',
      patientsCount: 0,
      color: HawaiianOrPacificColor
    },
    {
      name: 'Two or more races',
      patientsCount: 0,
      color: TwoOrMoreColor
    },
    {
      name: 'Prefer not to disclose',
      patientsCount: 0,
      color: prefetNotToDisclose
    }
  ];

  if (siteId !== 'none') {
    chartData = [
      {
        name: 'American Indian or Alaska Native',
        patientsCount: results?.totalAmericanOrAlaska || 0,
        color: AmericanOrAlaskaColor
      },
      {
        name: 'Asian',
        patientsCount: results?.totalAsian || 0,
        color: AsianColor
      },
      {
        name: 'Black or African American',
        patientsCount: results?.totalBlackOrAfrican || 0,
        color: BlackOrAfricanColor
      },
      {
        name: 'White',
        patientsCount: results?.totalWhite || 0,
        color: WhiteColor
      },
      {
        name: 'Native Hawaiian or other Pacific Islander',
        patientsCount: results?.totalHawaiianOrPacific || 0,
        color: HawaiianOrPacificColor
      },
      {
        name: 'Two or more races',
        patientsCount: results?.totalTwoOrMore || 0,
        color: TwoOrMoreColor
      },
      {
        name: 'Prefer not to disclose',
        patientsCount: results?.totalPreferNotToDisclose || 0,
        color: prefetNotToDisclose
      }
    ];
  }

  return chartData;
};

export const mapRaceOutputsToTable = patients =>
  patients.map(patient => ({
    id: patient?.patientId,
    name: patient?.patientName,
    age: patient?.age,
    site: patient?.siteName,
    assignedStaff: patient?.primaryStaffName,
    race: getRaceLabel[patient?.race]
  }));

export const mapRaceOutputsToExportedTable = (data, siteName) => [
  {
    id: 1,
    race: 'American Indian or Alaska Native',
    count: data?.totalAmericanOrAlaska,
    percentage: `${
      Math.round(
        (data?.totalAmericanOrAlaska / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 2,
    race: 'Asian',
    count: data?.totalAsian,
    percentage: `${
      Math.round((data?.totalAsian / (data?.totalPatients || 1)) * 100 * 10) /
      10
    }%`,
    site: siteName
  },
  {
    id: 3,
    race: 'Black or African American',
    count: data?.totalBlackOrAfrican,
    percentage: `${
      Math.round(
        (data?.totalBlackOrAfrican / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 4,
    race: 'White',
    count: data?.totalWhite,
    percentage: `${
      Math.round((data?.totalWhite / (data?.totalPatients || 1)) * 100 * 10) /
      10
    }%`,
    site: siteName
  },
  {
    id: 5,
    race: 'Native Hawaiian or other Pacific Islander',
    count: data?.totalHawaiianOrPacific,
    percentage: `${
      Math.round(
        (data?.totalHawaiianOrPacific / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 6,
    race: 'Two or more races',
    count: data?.totalTwoOrMore,
    percentage: `${
      Math.round(
        (data?.totalTwoOrMore / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  },
  {
    id: 6,
    race: 'Prefer not to disclose',
    count: data?.totalPreferNotToDisclose,
    percentage: `${
      Math.round(
        (data?.totalPreferNotToDisclose / (data?.totalPatients || 1)) * 100 * 10
      ) / 10
    }%`,
    site: siteName
  }
];
