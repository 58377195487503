export const calculateAge = (birthday: Date): string | undefined => {
  const birthDate = new Date(birthday);
  if (birthDate instanceof Date) {
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);

    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    return age?.toString();
  }
  return undefined;
};
